

















import Vue from "vue";
import { mapMutations } from "vuex";
import {
  RESET_PASS_USER_EMAIL,
  RESET_PASSWORD_TOKEN,
  USER_STATE
} from "@/store/modules/auth/constants";
import { UserState } from "@/store/modules/auth/interfaces";
import router from "@/router";

export default Vue.extend({
  name: "BackToLoginBtn",
  methods: {
    ...mapMutations("auth", {
      set_user_state: USER_STATE,
      set_reset_pass_email: RESET_PASS_USER_EMAIL,
      set_reset_pass_token: RESET_PASSWORD_TOKEN
    }),
    async handle_back() {
      this.set_user_state(UserState.UNAUTHENTICATED);
      this.set_reset_pass_email(null);
      this.set_reset_pass_token(null);
      await router.push("/auth/login");
    }
  }
});
